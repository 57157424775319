import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  Typography,
} from '@mui/material';
import { ErrorP } from '../../../common/Typography';
import { Dispatch, SetStateAction } from 'react';
import { Quote } from '../../../../Typescript/backend/classes';
import { SupportLink } from '../../../common/SupportLink';
import { formatAsReadableList } from '../../../common/QuoteComponents/utility';

interface QuoteBoundStatusChangeErrorDialogProps {
  quote: Quote;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
  editBindingsError?: Error;
  editDatesError?: Error;
  editInsurancePolicyError?: Error;
}
export const QuoteBoundStatusChangeErrorDialog = ({
  open,
  setOpen,
  editBindingsError,
  editDatesError,
  editInsurancePolicyError,
  quote,
}: QuoteBoundStatusChangeErrorDialogProps) => {
  if (!editDatesError && !editBindingsError && !editInsurancePolicyError) {
    return null;
  }
  return (
    <Dialog open={open}>
      <DialogTitle>Error Editing Bindings</DialogTitle>
      <DialogContent>
        <Typography>
          Error when updating the{' '}
          {formatAsReadableList([
            ...(editBindingsError ? ['bindings'] : []),
            ...(editDatesError ? ['dates'] : []),
            ...(editInsurancePolicyError ? ['policy number'] : []),
          ])}
          .
        </Typography>
        <ErrorDisplay
          quote={quote}
          editBindingsError={editBindingsError}
          editDatesError={editDatesError}
          editInsurancePolicyError={editInsurancePolicyError}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>OK</Button>
      </DialogActions>
    </Dialog>
  );
};

interface ErrorDisplayProps {
  quote: Quote;
  editBindingsError?: Error;
  editDatesError?: Error;
  editInsurancePolicyError?: Error;
}
export const ErrorDisplay = ({
  quote,
  editBindingsError,
  editDatesError,
  editInsurancePolicyError,
}: ErrorDisplayProps) => {
  if (editBindingsError) {
    return (
      <>
        <ErrorP sx={{ mb: 1 }}>{editBindingsError.message}</ErrorP>
        <ErrorP>
          If you believe this to be an error or have further questions, please
          contact support at{' '}
          <SupportLink
            subject={`Error editing bindings for application ${quote.getDisplayId()}`}
          />
        </ErrorP>
      </>
    );
  }
  if (editDatesError) {
    return (
      <>
        <ErrorP sx={{ mb: 1 }}>{editDatesError.message}</ErrorP>
        <ErrorP>
          If you believe this to be an error or have further questions, please
          contact support at{' '}
          <SupportLink
            subject={`Error editing policy effective dates for application ${quote.getDisplayId()}`}
          />
        </ErrorP>
      </>
    );
  }
  if (editInsurancePolicyError) {
    return (
      <>
        <ErrorP sx={{ mb: 1 }}>{editInsurancePolicyError.message}</ErrorP>
        <ErrorP>
          If you believe this to be an error or have further questions, please
          contact support at{' '}
          <SupportLink
            subject={`Error editing policy number for application ${quote.getDisplayId()}`}
          />
        </ErrorP>
      </>
    );
  }
  return null;
};

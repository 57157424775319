/*
 * NB You can actually upload different file types here as well
 */
import { useState, ChangeEvent, useEffect } from 'react';
import Button from '@mui/material/Button';
import { BusinessType } from '../../Typescript';
import { VisuallyHiddenInput } from '../common/VisuallyHiddenInput';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  styled,
  Typography,
} from '@mui/material';
import { useMutation, useLazyQuery } from '@apollo/client';
import { CREATE_QUOTE_FROM_PDF, GET_TASK_POOL } from '../../queries';
import { useNavigate } from '@reach/router';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { CoreTaskStatusChoices, GetTaskPoolQuery } from '../../gql/graphql';
import Bugsnag from '@bugsnag/browser';
import { errorify } from '@calefy-inc/utility';
import { LinearProgress } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import { ErrorP } from '../common/Typography';
import { SupportLink } from '../common/SupportLink';
import { useUsingVagoSettings } from '../../hooks';
import ErrorIcon from '@mui/icons-material/Error';
import { isNotNullish } from '../../util';

type BackendTask = NonNullable<GetTaskPoolQuery['getTaskPool']>['tasks'];

const POLLING_INTERVAL_MS = 500;

interface CreateApplicationFromPdfButtonProps {
  businessLine: BusinessType;
}
export const CreateApplicationFromPdfButton = ({
  businessLine,
}: CreateApplicationFromPdfButtonProps) => {
  const onVago = useUsingVagoSettings();
  const [fileModalOpen, setFileModalOpen] = useState<boolean>(false);
  const [fileB64, setFileB64] = useState<string>();
  const [fileExt, setFileExt] = useState<string>();

  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.target.files && event.target.files.length > 0) {
      //console.log('just received file');
      const file = event.target.files[0];
      const extension = file.name.split('.').pop(); // Extract the file extension
      // @ts-expect-error
      setFileExt(extension || null); // Handle files without extensions
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setFileB64(reader.result as string);
      };
      reader.onerror = (error) => {
        console.error('Error: ', error);
      };
      setFileModalOpen(true);
    }
  };

  const onCancel = () => setFileModalOpen(false);
  const onSuccess = () => setFileModalOpen(false);
  if (!onVago) {
    return null;
  }
  return (
    <>
      <Button
        component='label'
        variant='outlined'
        startIcon={<PictureAsPdfIcon />}
      >
        Upload Application
        <VisuallyHiddenInput
          type='file'
          accept='.pdf,.xlsx,.xls'
          onChange={handleFileUpload}
        />
      </Button>
      <UploadFileDialog
        fileB64={fileB64}
        fileExtension={fileExt}
        businessLine={businessLine}
        open={fileModalOpen}
        onCancel={onCancel}
        onSuccess={onSuccess}
      />
    </>
  );
};

interface UploadFileDialogProps {
  open: boolean;
  onCancel: () => void;
  onSuccess: () => void;
  fileB64?: string;
  fileExtension?: string;
  businessLine: BusinessType;
}
const UploadFileDialog = ({
  open,
  onCancel,
  // @ts-expect-error
  onSuccess,
  fileB64,
  fileExtension,
  businessLine,
}: UploadFileDialogProps) => {
  const [destinationUuid, setDestinationUuid] = useState<string>();
  const [tasks, setTasks] = useState<Array<BackendTask>>([]);
  const [aboutToNavigate, setAboutToNavigate] = useState<boolean>(false);
  const navigate = useNavigate();
  const [
    createQuoteFromPdf,
    { error: createError, data: createData, reset: resetCreateQuote },
  ] = useMutation(CREATE_QUOTE_FROM_PDF);
  const [
    getTaskPool,
    { error: taskPoolError, data: taskPoolData, stopPolling },
  ] = useLazyQuery(GET_TASK_POOL);

  useEffect(() => {
    if (open && fileB64 && businessLine && fileExtension) {
      createQuoteFromPdf({
        variables: {
          businessLineId: businessLine.id,
          policyIds: [],
          quotePdf: fileB64,
          extension: fileExtension,
        },
      });
    }
  }, [open, fileB64, businessLine, fileExtension]);

  // once the data has been loaded, start polling for the task pool
  useEffect(() => {
    if (createData?.createQuoteFromPdf?.taskPoolId) {
      getTaskPool({
        variables: { taskPoolId: createData.createQuoteFromPdf.taskPoolId },
        // @ts-expect-error
        pollInterval: POLLING_INTERVAL_MS,
      });
    }
  }, [createData]);

  // set the tasks and the destination uuid if that becomes part of the task pool
  useEffect(() => {
    if (taskPoolData?.getTaskPool) {
      try {
        const additionalInformationJson =
          taskPoolData?.getTaskPool?.additionalInformation;
        if (additionalInformationJson) {
          const parsed = JSON.parse(additionalInformationJson);
          const uuid = parsed.quoteUuid;
          if (uuid) {
            setDestinationUuid(uuid);
          }
        }
      } catch (e) {
        console.error(e);
        Bugsnag.notify(errorify(e));
      }
      setTasks(
        // @ts-expect-error
        [...taskPoolData.getTaskPool.tasks]
          .filter(isNotNullish)
          // @ts-expect-error
          .sort((a, b) => a.order - b.order),
      );
    }
  }, [taskPoolData]);

  // stop polling if there's an error or if all of the tasks are complete
  useEffect(() => {
    if (!stopPolling) {
      return;
    }
    if (taskPoolError) {
      stopPolling();
    }
    // NB we don't do this because the last task is completed *and then* we attach the uuid -> possible to be stuck in a state where they are all complete but no uuid to navigate to
    // if (
    //   taskPoolData?.getTaskPool?.tasks &&
    //   taskPoolData.getTaskPool.tasks.length > 0 &&
    //   taskPoolData.getTaskPool.tasks.every(
    //     (task) => task.status === CoreTaskStatusChoices.Completed,
    //   )
    // ) {
    //   stopPolling();
    // }
    if (aboutToNavigate) {
      stopPolling();
    }
    if (taskPoolData?.getTaskPool?.error) {
      stopPolling();
    }
  }, [taskPoolError, taskPoolData, aboutToNavigate]);

  useEffect(() => {
    if (destinationUuid) {
      setAboutToNavigate(true);
    }
  }, [destinationUuid]);

  // set the timer to navigate
  useEffect(() => {
    if (aboutToNavigate) {
      setTimeout(
        () => navigate(`/insurtech/quote/resume/${destinationUuid}`),
        2000,
      );
    }
  }, [aboutToNavigate]);

  //console.log({ tasks });

  return (
    <Dialog open={open}>
      <DialogTitle>Uploading Quote / Application Document</DialogTitle>
      <DialogContent sx={{ marginBottom: 0 }}>
        {tasks.length > 0 &&
        tasks.some(
          // @ts-expect-error
          (task) => task.status === CoreTaskStatusChoices.Pending,
        ) &&
        !createError &&
        !taskPoolError ? (
          <Typography sx={{ my: 2 }}>Loading File...</Typography>
        ) : null}
        <DisplayTaskProgress tasks={tasks} />
        <ErrorDisplay
          createError={createError}
          taskPoolError={taskPoolError}
          // @ts-expect-error
          taskPoolErrorString={taskPoolData?.getTaskPool?.error}
        />
        {aboutToNavigate ? (
          <Typography
            variant='h4'
            component='p'
            sx={{
              mt: 4,
              textAlign: 'center',
            }}
          >
            About to load quote...
          </Typography>
        ) : null}
      </DialogContent>
      <DialogActions sx={{ marginTop: 0 }}>
        <Button
          onClick={() => {
            resetCreateQuote();
            stopPolling();
            setTasks([]);
            onCancel();
          }}
          disabled={
            aboutToNavigate ||
            (!createError &&
              !taskPoolError &&
              !taskPoolData?.getTaskPool?.error)
          }
        >
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface DisplayTaskProgressContainer {
  tasks: Array<BackendTask>;
}
const DisplayTaskProgress = ({ tasks }: DisplayTaskProgressContainer) => {
  return (
    <DisplayTaskProgressContainer>
      {tasks.map((task, taskIndex) => {
        let value = 0;
        // @ts-expect-error
        if (task.status === CoreTaskStatusChoices.Completed) {
          value = 100;
        }
        return (
          <>
            {taskIndex > 0 ? (
              <DividerLinearProgress
                key={
                  // @ts-expect-error
                  task.id
                }
                value={value}
                variant='determinate'
              />
            ) : null}
            <TaskDisplay
              task={task}
              key={
                // @ts-expect-error
                task.id
              }
            />
          </>
        );
      })}
    </DisplayTaskProgressContainer>
  );

  {
    /* return <pre>{JSON.stringify(tasks, null, 4)}</pre>; */
  }
};

const DividerLinearProgress = styled(LinearProgress)({
  width: '5rem',
  // width: `calc(100% - ${2 * 1.5}rem)`,
  transform: `translate(0, -1.5rem)`, // NB this is fragile - it depends on at least on piece of text wrapping to the next line
  top: 0,
  height: '.15rem',
});

const DisplayTaskProgressContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'flex-start',
});

const AnimatedLoadingCircle = styled(ChangeCircleIcon)(
  `
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  animation: rotate 2s linear infinite;
`,
);

interface TaskDisplayProps {
  task: BackendTask;
}
const TaskDisplay = ({ task }: TaskDisplayProps) => {
  return (
    <TaskDisplayContainer>
      {
        // @ts-expect-error
        task.status === CoreTaskStatusChoices.Completed ? (
          <CheckCircleIcon color={'primary'} />
        ) : // @ts-expect-error
        task.status === CoreTaskStatusChoices.Pending ? (
          <AnimatedLoadingCircle />
        ) : // @ts-expect-error
        task.status === CoreTaskStatusChoices.Failed ? (
          <ErrorIcon sx={{ color: 'error.main' }} />
        ) : (
          <span></span>
        )
      }
      <Typography>
        {
          // @ts-expect-error
          task.description
        }
      </Typography>
    </TaskDisplayContainer>
  );
};

const TaskDisplayContainer = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  alignSelf: 'flex-start',
  textAlign: 'center',
});

interface ErrorDisplayProps {
  createError?: Error;
  taskPoolError?: Error;
  taskPoolErrorString?: string;
}
const ErrorDisplay = ({
  createError,
  taskPoolError,
  taskPoolErrorString,
}: ErrorDisplayProps) => {
  const [message, setMessage] = useState<string>();

  useEffect(() => {
    if (createError) {
      setMessage(createError.message);
    } else if (taskPoolError) {
      setMessage(taskPoolError.message);
    } else if (taskPoolErrorString) {
      setMessage(taskPoolErrorString);
    } else {
      setMessage('');
    }
  }, [createError, taskPoolError, taskPoolErrorString]);

  if (!message) {
    return null;
  }
  return (
    <>
      <ErrorP>An unexpected error occurred: {message}.</ErrorP>
      <ErrorP>
        Please try again in a few minutes. If the error persists or you have any
        questions, please contact support at{' '}
        <SupportLink subject={`Error loading quote from pdf: ${message}`} />.
      </ErrorP>
    </>
  );
};

// @ts-nocheck
import { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  EDIT_BINDINGS,
  EDIT_INSURANCE_POLICY,
  EDIT_INSURANCE_POLICY_DATES,
} from '../../../../queries';
// import Button from '@mui/material/Button';
import { Quote } from '../../../../Typescript/backend/classes';
import Label from '../../components/Label';
import {
  useDownloadVagoDocumentation,
  useQuoteInsurancePolicies,
  useUsingVagoSettings,
} from '../../../../hooks';
import { useAuth } from '@calefy-inc/authentication';
// import { CircularProgress } from '@mui/material';
import { useSnackbar } from 'notistack';
// import Bugsnag from '@bugsnag/browser';
// import { errorify } from '@calefy-inc/utility';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '@mui/material/Tooltip';
import { isNotNullish } from '../../../../util';
import { EditBindingInput } from '../../../../gql/graphql';
import {
  Form,
  Checkbox,
  TextArea,
  Calendar,
  TextField,
} from '@calefy-inc/informedMaterial';
import { ArrayField } from 'informed';
import { Binding } from '../../../../Typescript/backend/classes/Quote';
import { InsurancePolicy } from '../../../../Typescript/classes';
import { QuoteBoundStatusChangeErrorDialog } from './QuoteBoundStatusChangeErrorDialog';

interface QuoteBoundStatusProps {
  quote: Quote;
}
export const QuoteBoundStatus = ({ quote }: QuoteBoundStatusProps) => {
  const { token } = useAuth();
  const onVago = useUsingVagoSettings();
  const { enqueueSnackbar } = useSnackbar();
  const [editBindings, { loading, error, data }] = useMutation(EDIT_BINDINGS, {
    refetchQueries: ['Quote'],
  });
  const [downloadVagoDocumentation, _otherRest] = useDownloadVagoDocumentation({
    uuid: quote.uniqueId,
    onSuccess: () =>
      enqueueSnackbar('Downloaded updated documenation', {
        variant: 'success',
      }),
    onFailure: () =>
      enqueueSnackbar('Error downloading updated documentation', {
        variant: 'error',
      }),
  });
  const [editInsurancePolicyDates, { error: editDatesError }] = useMutation(
    EDIT_INSURANCE_POLICY_DATES,
    {
      refetchQueries: ['quoteInsurancePolicies'],
      onCompleted: () => {
        downloadVagoDocumentation && downloadVagoDocumentation();
      },
    },
  );
  const [editInsurancePolicy, { error: editInsurancePolicyError }] =
    useMutation(EDIT_INSURANCE_POLICY, {
      refetchQueries: ['quoteInsurancePolicies'],
    });
  const [bindings, setBindings] = useState<Quote['bindings']>([
    ...quote.bindings,
  ]);
  const [confirmationOpen, setConfirmationOpen] = useState<boolean>(false);
  const [showBindButton, setShowBindButton] = useState<boolean>(
    quote.bindings.length > 0,
  );
  const [errorDialogOpen, setErrorDialogOpen] = useState<boolean>(false);

  // update the bound status
  useEffect(() => {
    if (data?.editBindings?.bindings) {
      setBindings([
        ...data.editBindings.bindings
          .filter(isNotNullish)
          .map((input) => new Binding(input)),
      ]);
    }
  }, [data]);

  // update the error message
  useEffect(() => {
    if (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }, [error]);

  // update whether or not we should show the button to bind / unbind the quote
  useEffect(() => {
    if (quote) {
      const bindingsExist = quote.bindings.length > 0;
      setShowBindButton(bindingsExist);
    }
  }, [token, quote]);

  // whenever the quote updates, we should update the bindings
  useEffect(() => {
    setBindings([...quote.bindings]);
  }, [quote]);

  // open the error dialog when appropriate
  useEffect(() => {
    if (error || editDatesError) {
      setErrorDialogOpen(true);
    }
  }, [error, editDatesError]);

  if (!onVago) {
    return null;
  }
  if (bindings.length === 0) {
    return null;
  }
  return (
    <BoundContainer>
      <BoundStatusContainer>
        {bindings.map((binding) => {
          return (
            <Tooltip
              title={`${binding.label} ${
                binding.bound ? 'is' : 'is not'
              } bound`}
            >
              <BoundLabelContainer>
                <Label color={binding.bound ? 'success' : 'warning'}>
                  {binding.label}{' '}
                </Label>
                {binding.bound ? (
                  <CheckIcon sx={{ color: 'success.main' }} />
                ) : (
                  <CloseIcon sx={{ color: 'error.main' }} />
                )}
              </BoundLabelContainer>
            </Tooltip>
          );
        })}
      </BoundStatusContainer>
      {showBindButton ? (
        <LoadingButton
          loading={loading}
          disabled={!token}
          onClick={() => setConfirmationOpen(true)}
        >
          Modify Bound
        </LoadingButton>
      ) : null}
      <MarkQuoteBoundConfirmationDialog
        quote={quote}
        open={confirmationOpen}
        onCancel={() => setConfirmationOpen(false)}
        onConfirm={({
          bindings,
          insurancePolicyId,
          policyEffectiveDate,
          policyNumber,
        }: {
          bindings: Array<EditBindingInput>;
          insurancePolicyId?: InsurancePolicy['id'];
          policyEffectiveDate?: string;
          policyNumber?: InsurancePolicy['policyNumber'];
        }) => {
          editBindings({
            variables: {
              token,
              bindingsToEdit: bindings,
            },
          });
          if (policyEffectiveDate && insurancePolicyId) {
            const actualEffectiveDate = convertDate(policyEffectiveDate);
            const actualExpiryDate = actualEffectiveDate
              ? new Date(actualEffectiveDate)
              : undefined;
            if (actualExpiryDate && actualEffectiveDate) {
              actualExpiryDate.setFullYear(
                // @ts-expect-error
                actualEffectiveDate.getFullYear() + 1,
              );
            }
            editInsurancePolicyDates({
              variables: {
                token,
                insurancePolicyId: insurancePolicyId,
                policyEffectiveDate: actualEffectiveDate
                  // @ts-expect-error
                  ?.toISOString()
                  .split('T')[0],
                policyExpiryDate: actualExpiryDate?.toISOString().split('T')[0],
              },
            });
          }
          if (policyNumber && insurancePolicyId) {
            editInsurancePolicy({
              variables: {
                token,
                insurancePolicyId: insurancePolicyId,
                policyNumber,
              },
            });
          }
          setConfirmationOpen(false);
        }}
      />
      {bindings.map((binding) =>
        binding.unboundReason ? (
          <Typography variant='subtitle2'>
            {binding.label} unbound reason: {binding.unboundReason}
          </Typography>
        ) : null,
      )}
      <QuoteBoundStatusChangeErrorDialog
        quote={quote}
        open={errorDialogOpen}
        setOpen={setErrorDialogOpen}
        editBindingsError={error}
        editDatesError={editDatesError}
        editInsurancePolicyError={editInsurancePolicyError}
      />
    </BoundContainer>
  );
};

const BoundStatusContainer = styled('div')(({ theme }) => {
  return {
    // display: 'grid',
    // gridTemplateColumns: 'repeat(4, auto)',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'baseline',
    justifyContent: 'flex-end',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
  };
});

const BoundContainer = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    justifyContent: 'center',
    gap: theme.spacing(1),
  };
});

interface MarkQuoteBoundConfirmationDialogProps {
  quote: Quote;
  open: boolean;
  onConfirm: (param: {
    bindings: Array<EditBindingInput>;
    policyEffectiveDate?: string;
    insurancePolicyId?: string;
    policyNumber?: InsurancePolicy['policyNumber'];
  }) => void;
  onCancel: () => void;
}
const MarkQuoteBoundConfirmationDialog = ({
  quote,
  open,
  onConfirm,
  onCancel,
}: MarkQuoteBoundConfirmationDialogProps) => {
  const [insurancePolicy, setInsurancePolicy] = useState<
    InsurancePolicy | undefined
  >();
  const [policyEffectiveDate, setPolicyEffectiveDate] = useState<
    string | undefined
  >();
  const [insurancePolicies, ..._rest] = useQuoteInsurancePolicies(quote);

  useEffect(() => {
    if (insurancePolicies.length > 0) {
      setInsurancePolicy(insurancePolicies[0]);
    }
  }, [insurancePolicies]);

  // set the effective date
  useEffect(() => {
    if (insurancePolicy) {
      const effectiveDate = insurancePolicy.rawPolicyEffectiveDate;
      setPolicyEffectiveDate(effectiveDate);
    }
  }, [insurancePolicy]);

  return (
    <Dialog open={open}>
      <Form
        initialValues={{
          bindings: quote.bindings,
          policyEffectiveDate: policyEffectiveDate,
          policyNumber: insurancePolicy?.policyNumber,
        }}
        onSubmit={
          // @ts-expect-error
          (values) => {
            const bindingEdits: Array<EditBindingInput> = values.bindings
              .map(
                // @ts-expect-error
                (binding, index) => {
                  const matching =
                    index in quote.bindings ? quote.bindings[index] : null;
                  if (!matching) {
                    return null;
                  }
                  return {
                    bindingId: matching.id,
                    bound: binding.bound,
                    unboundReason: binding.unboundReason,
                  };
                },
              )
              .filter(isNotNullish);
            const { policyNumber } = values;
            const policyEffectiveDateDifferent =
              policyEffectiveDate !== insurancePolicy?.policyEffectiveDate;
            const onConfirmParams: Parameters<typeof onConfirm>[0] = {
              bindings: bindingEdits,
            };
            if (insurancePolicy) {
              onConfirmParams['insurancePolicyId'] = insurancePolicy.id;
            }
            if (policyNumber !== insurancePolicy?.policyNumber) {
              onConfirmParams['policyNumber'] = policyNumber;
            }
            if (policyEffectiveDateDifferent) {
              onConfirmParams['policyEffectiveDate'] = policyEffectiveDate;
            }
            onConfirm(onConfirmParams);
          }
        }
      >
        <>
          <DialogTitle>Confirmation</DialogTitle>
          <DialogContent>
            <Typography>
              Please be careful when editing the status of coverages / policies.
              Having any of these as bound will prevent further edits to the
              application, and any changes made will immediately affect the
              rating document and any generated bordereau files.
            </Typography>
            <ArrayField field='bindings'>
              {
                // @ts-expect-error
                ({ arrayFieldApi, arrayFieldState }) => {
                  return (
                    <CoverageTable>
                      <thead>
                        <tr>
                          <th scope='col'>Coverage</th>
                          <th scope='col'>Bound?</th>
                          <th scope='col'>Unbinding Reason</th>
                        </tr>
                      </thead>
                      <tbody>
                        {/*
                            // @ts-expect-error */}
                        <ArrayField.Items>
                          {({
                            // @ts-expect-error
                            index,
                            // @ts-expect-error
                            field,
                            // @ts-expect-error
                            arrayFieldItemApi,
                            // @ts-expect-error
                            arrayFieldItemState,
                          }) => {
                            const matchingBinding =
                              index in quote.bindings
                                ? quote.bindings[index]
                                : null;
                            if (!matchingBinding) {
                              return null;
                            }
                            const needsUnboundReason =
                              matchingBinding.bound &&
                              !arrayFieldItemState?.values?.bound;
                            return (
                              <tr>
                                <CoverageTd>{matchingBinding.label}</CoverageTd>
                                <CheckboxTd>
                                  <Checkbox field={`${field}.bound`} />
                                </CheckboxTd>
                                <td>
                                  {
                                    // we want to show it if
                                    // 1. it's not bound and it used to be OR it's not bound and there's a reason
                                    // 2. It needs to be there
                                    needsUnboundReason ||
                                    (!arrayFieldItemState?.values?.bound &&
                                      arrayFieldItemState?.initialValue
                                        ?.unboundReason) ? (
                                      !needsUnboundReason ? ( // if they don't need it, don't display it as a textarea; that's confusing
                                        <Typography>
                                          {matchingBinding.unboundReason}
                                        </Typography>
                                      ) : (
                                        <TextArea
                                          fullWidth
                                          required={needsUnboundReason}
                                          disabled={!needsUnboundReason}
                                          label={`Reason for unbinding ${matchingBinding.label}`}
                                          field={`${field}.unboundReason`}
                                        />
                                      )
                                    ) : (
                                      <Typography>
                                        <em>Not needed</em>
                                      </Typography>
                                    )
                                  }
                                </td>
                              </tr>
                            );
                          }}
                          {/*
                        // @ts-expect-error */}
                        </ArrayField.Items>
                      </tbody>
                    </CoverageTable>
                  );
                }
              }
            </ArrayField>
            {insurancePolicy ? (
              <Calendar
                field='policyEffectiveDate'
                label={'Policy Effective Date'}
                value={convertDate(policyEffectiveDate)}
                onChange={
                  // @ts-expect-error
                  (value) => {
                    setPolicyEffectiveDate(dateToString(value));
                  }
                }
              />
            ) : null}
            <br />
            {insurancePolicy ? (
              <TextField
                field='policyNumber'
                label='Policy Number'
                required={true}
              />
            ) : null}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                onCancel();
              }}
            >
              Cancel
            </Button>
            <Button type='submit'>Apply Changes</Button>
          </DialogActions>
        </>
        );
      </Form>
    </Dialog>
  );
};

const convertDate = (dateString?: string) => {
  if (!dateString) {
    return dateString;
  }
  const asDate = new Date(dateString);
  return new Date(asDate.getTime() + asDate.getTimezoneOffset() * 60 * 1000);
};

// @ts-expect-error
const prepareDateForBackend = (date?: Date | string) => {
  if (!date || typeof date === 'string') {
    return undefined;
  }
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const day = String(date.getDate()).padStart(2, '0');
  const dateString = `${month}/${day}/${year}`;
  return dateString;
};

const dateToString = (date: Date): string => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

// Example usage:
const date = new Date(2024, 10, 14); // November 14, 2024 (Month is 0-based)
console.log(dateToString(date)); // Outputs: 2024-11-14

const CoverageTable = styled('table')({
  width: '100%',
});
const CoverageTd = styled('td')(({ theme }) => {
  return {
    paddingRight: theme.spacing(1),
  };
});
const CheckboxTd = styled('td')({
  textAlign: 'center',
});

const BoundLabelContainer = styled('div')(({ theme }) => {
  return {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: theme.spacing(1),
  };
});

// @ts-expect-error
const ArrayFieldItemContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
});

import { Dispatch, SetStateAction, useState, useEffect } from 'react';
import {
  StyledTable,
  StyledTr,
  StyledTh,
  StyledTbody,
  StyledThead,
} from '../PremiumOverridesTable';
import { DisplayCoverageContainer } from '../InsurancePolicyDisplay';
import Typography from '@mui/material/Typography';
import {
  EditPerilInput,
  VagoCommercialPackBindingOptions,
} from '../../../../../../gql/graphql';
import { PerilRow } from '../InsurancePolicyDisplay';
import { VagoAdditionalCoveragePerilNames } from './constants';
import { CombinedPerilsRow } from '../CombinedPerilsRow';
import { Binding } from '../../../../../../Typescript/backend/classes/Quote';
import { Peril } from '../../../../../../Typescript/classes';

interface VagoDisplayAdditionalCoveragesProps {
  bindings: Array<Binding>;
  perils: Array<Peril>;
  setPerilsToEdit: Dispatch<SetStateAction<Array<EditPerilInput>>>;
}
export const VagoDisplayAdditionalCoverages = ({
  bindings,
  perils,
  setPerilsToEdit,
}: VagoDisplayAdditionalCoveragesProps) => {
  const [crimePerils, setCrimePerils] = useState<Array<Peril>>([]);
  const [legalSolutionPerils, setLegalSolutionPerils] = useState<Array<Peril>>(
    [],
  );
  const [boilerPerils, setBoilerPerils] = useState<Array<Peril>>([]);

  // update the different categories when the perils come in
  useEffect(() => {
    setCrimePerils(
      perils.filter(
        (peril) => peril.name === VagoAdditionalCoveragePerilNames.CRIME,
      ),
    );
    setLegalSolutionPerils(
      perils.filter(
        (peril) =>
          peril.name === VagoAdditionalCoveragePerilNames.LEGAL_SOLUTION,
      ),
    );
    setBoilerPerils(
      perils
        .filter(
          (peril) =>
            peril.name === VagoAdditionalCoveragePerilNames.EQUIPMENT_BREAKDOWN,
        )
        .map((peril) => peril.copyWithAmendments({ rate: undefined })),
    );
  }, [perils]);

  if (perils.length === 0) {
    return null;
  }
  return (
    <DisplayCoverageContainer>
      <Typography component='h3' variant='h5' sx={{ alignSelf: 'start' }}>
        Additional Coverages
      </Typography>
      <StyledTable>
        <StyledThead>
          <StyledTr>
            {['Peril', 'Limits', 'Rate', 'Premium', 'Override Premium?'].map(
              (header) => (
                <StyledTh scope='col' key={header}>
                  {header}
                </StyledTh>
              ),
            )}
          </StyledTr>
        </StyledThead>
        <StyledTbody>
          <CombinedPerilsRow
            perils={crimePerils}
            updatePerils={setPerilsToEdit}
            bound={
              !!bindings.find(
                (binding) =>
                  binding.matchesLabel(
                    VagoCommercialPackBindingOptions.Property,
                  ) && binding.bound,
              )
            }
          />
          {boilerPerils.map((peril) => (
            <PerilRow
              key={peril.id}
              peril={peril}
              updatePerils={setPerilsToEdit}
              bound={
                !!bindings.find(
                  (binding) =>
                    binding.matchesLabel(
                      VagoCommercialPackBindingOptions.Boiler,
                    ) && binding.bound,
                )
              }
            />
          ))}
          <CombinedPerilsRow
            perils={legalSolutionPerils}
            updatePerils={setPerilsToEdit}
            sumPremiums={false}
            bound={
              !!bindings.find(
                (binding) =>
                  binding.matchesLabel(
                    VagoCommercialPackBindingOptions.LegalSolutions,
                  ) && binding.bound,
              )
            }
          />
        </StyledTbody>
      </StyledTable>
    </DisplayCoverageContainer>
  );
};

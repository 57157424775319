import { InvalidUUIDError, MissingQuoteError } from './ErrorComponents';

// classes and types
import { BusinessType } from '../../../Typescript';
import type { $TSFixMe } from '@calefy-inc/utilityTypes';
import { Language } from '../../../Typescript/classes';
import {
  QuoteWizardForm,
  QuoteWizardAnswerInstance,
  QuoteWizardQuestionInstance,
} from '../classes';
import { PolicySummary } from '../types';
import { BackendCompletedForm } from '../../../Typescript/backend/classes';
import { ProgramBuilderForm } from '../../FormManager/classes';

import type { QuoteStatus } from '../../../Typescript/backend/types';
import { UnifiedAnswerInstance } from '../../../Typescript/classes';

const findQuestionInstanceInList = (
  question: QuoteWizardQuestionInstance,
  list: Array<QuoteWizardQuestionInstance>,
): null | QuoteWizardQuestionInstance => {
  //console.log('Beginning search for ', question, 'in ', list);
  if (!list || list.length === 0) {
    //console.log('Empty list - exiting search');
    return null;
  }

  let foundQuestion: QuoteWizardQuestionInstance | null;
  for (let formQuestion of list) {
    // check only against the apiName so that minor changes to e.g. the question's label are accounted for
    if (question.apiName === formQuestion.apiName) {
      /*console.log('Found matching question for', question, formQuestion); */
      foundQuestion = formQuestion;
      return foundQuestion;
    } else {
      foundQuestion = findQuestionInstanceInList(
        question,
        formQuestion.subQuestions,
      );
      if (foundQuestion) {
        /*console.log( */
        /*   'Found matching question (as subquestion) for', */
        /*   question, */
        /*   formQuestion, */
        /* ); */
        return foundQuestion;
      }
    }
  }
  /*console.log('No matched question for', question); */
  return null;
};

export const findMatchingBusinessInformationForm = (
  businessLine: $TSFixMe,
  formsList: $TSFixMe,
) => {
  return formsList.find(
    (form: $TSFixMe) => form.businessLine.id === businessLine.id,
  );
};

/* export const findMatchingPolicyForm = ( */
/*   businessLine: BusinessType, */
/*   policy: $TSFixMe, */
/*   formsList: Array<$TSFixMe>, */
/* ) => { */
/*   return formsList.find( */
/*     (form) => */
/*       form.businessLine.id === businessLine.id && */
/*       form.policy && */
/*       form.policy.id === policy.id, */
/*   ); */
/* }; */

export interface ParsedAnswers {
  form: QuoteWizardForm;
  answers: Array<QuoteWizardAnswerInstance>;
}
interface ParseDataReturn {
  businessLine: BusinessType;
  selectedPolicies: Array<PolicySummary>;
  businessForm: QuoteWizardForm;
  policyForms: Array<QuoteWizardForm>;
  parsedAnswers: Array<ParsedAnswers>;
}
/**
 * Parses the quote data returned from the backend and packages it so we can translate it into the Redux store
 * quoteData - the original quote information (response from the backend)
 * formsData - the up-to-date version of all forms, &c. (response from the backend)
 * quoteStatus - the status of the quote - we need to change what we actually do based on whether it is renewing, &c.
 */
export const parseData = (
  quoteData: $TSFixMe,
  formsData: $TSFixMe,
  quoteStatus: QuoteStatus,
): ParseDataReturn => {
  const businessLine = quoteData.quote.businessLine as BusinessType;

  // if the quote knowws its selected policies, use those; otherwise harvest them from the forms
  //console.log({ quoteData });
  const selectedPolicies: Array<Partial<PolicySummary>> =
    quoteData.quote.completedForms
      .filter((completedForm: $TSFixMe) => !!completedForm.policy)
      .map((completedForm: $TSFixMe) => ({ ...completedForm.policy }));

  let businessForm: QuoteWizardForm;
  const policyForms: Array<QuoteWizardForm> = [];
  const parsedAnswers: Array<ParsedAnswers> = [];

  // get all of the completed forms
  const allQuoteForms: Array<BackendCompletedForm> =
    quoteData.quote.completedForms.map((form: $TSFixMe) =>
      BackendCompletedForm.generateFromBackendResponse(form),
    );
  const quoteLanguage = new Language(
    quoteData.quote.language.shortName,
    quoteData.quote.language.fullName,
  );
  // get the actual forms as they would have appeared in the QuotePolicySelection
  // 1. Convert to ProgramBuilderForm
  // 2. Filter out the ones that aren't available in the language in which the quote was completed
  // 3. Convert the remaining to a QuoteWizardForm in the appropriate language
  const allActualForms: Array<QuoteWizardForm> = formsData.someFinalForms
    .map((form: $TSFixMe) =>
      ProgramBuilderForm.generateFromBackendResponse(form),
    )
    .filter((form: ProgramBuilderForm) =>
      quoteLanguage.inLanguageList(form.getAvailableLanguages()),
    )
    .map((form: ProgramBuilderForm) => form.toQuoteWizardForm(quoteLanguage))
    .map((form: QuoteWizardForm) =>
      (quoteStatus === 'RENEWAL_IN_PROGRESS' ||
        quoteStatus === 'INCOMPLETE_RENEWAL') &&
      form.hasRenewalQuestions()
        ? form.filterQuestions(
            (question: QuoteWizardQuestionInstance) => question.askOnRenewal,
          )
        : form,
    );

  // now go through the policy forms and use that to set the `required` and `qualifyingQuestion` fields on the parsed policies
  selectedPolicies.forEach((policy) => {
    const relatedForm = allActualForms.find(
      (form) => form.policy && form.policy.id === policy.id,
    );
    if (!relatedForm) {
      return;
    }
    policy.required = relatedForm.required;
    policy.qualifyingQuestion = relatedForm.qualifyingQuestion;
  });

  // get all policies that exist in a form and grab the business information and policy forms
  // (just split them into the businessForm and policyForms)
  allActualForms.forEach((form) => {
    form.businessLine = businessLine;
    if (form.policy) {
      // selectedPolicies.push(form.policy);
      policyForms.push(form);
    } else {
      businessForm = form;
    }
  });

  // now parse the answers
  // iterate through all of the quote forms
  //console.log({ allQuoteForms, allActualForms });
  allQuoteForms.forEach((form) => {
    const matchingForm = form.findMatchByPolicy(allActualForms);
    if (!matchingForm) {
      //if the form was not found, stop looking for answers
      return;
    }

    // steps:
    // 1. for each AnswerInstance, find the matching QuoteWizardQuestionInstance in the matchingForm
    // 2. Create a new QuestionWizardAnswerInstance from the matching QuoteWizardQuestionInstance iff the answer is not N/A
    // recurse on subAnswers and questionInstance.subQuestions
    let answersSoFar: Array<QuoteWizardAnswerInstance> = [];
    form.answers.forEach((answer) => {
      const mappedAnswer = mapAnswerToReduxState(
        answer,
        matchingForm.questionInstances,
        quoteLanguage,
        quoteStatus === 'RENEWAL_IN_PROGRESS' &&
          matchingForm.hasRenewalQuestions(),
      );
      if (mappedAnswer) {
        answersSoFar.push(mappedAnswer);
      } else {
        //console.log(
        //   `Unable to find matching answer for ${answer} in`,
        //   matchingForm.questionInstances,
        // );
      }
    });
    parsedAnswers.push({
      form: matchingForm,
      answers: answersSoFar.filter((ele) => ele !== null),
    });
  });

  const parsed: ParseDataReturn = {
    businessLine,
    // @ts-expect-error
    selectedPolicies,
    // @ts-expect-error
    businessForm,
    policyForms,
    parsedAnswers: parsedAnswers.filter((elem) => elem.answers !== null),
  };
  return parsed;
};

function mapAnswerToReduxState(
  answer: UnifiedAnswerInstance,
  questionList: Array<QuoteWizardQuestionInstance>,
  language: Language,
  stripRenewalAnswers: boolean = false,
): QuoteWizardAnswerInstance | null {
  const originalQuestion = answer.toQuoteWizardQuestionInstance(language);
  const matchedQuestionInstance = findQuestionInstanceInList(
    originalQuestion,
    questionList,
  );
  if (!matchedQuestionInstance || answer.value === 'N/A') {
    return null;
  }

  // don't prefill answers which shouldn't be on renewal
  if (stripRenewalAnswers && !matchedQuestionInstance.prefillOnRenewal) {
    return null;
  }
  /*console.log( */
  /*   'Constructing new QuoteWizardAnswerInstance from answer', */
  /*   answer, */
  /* ); */
  let parsedValue: unknown;
  if (answer.value === undefined) {
    parsedValue = undefined;
  } else if (answer.component === 'checkbox') {
    parsedValue =
      answer.value === 'true'
        ? true
        : answer.value === 'false'
        ? false
        : undefined;
  } else {
    parsedValue = answer.value;
  }
  const input = {
    questionInstance: matchedQuestionInstance,
    value: parsedValue,
    subAnswers:
      answer.subAnswers && answer.subAnswers.length > 0
        ? answer.subAnswers.reduce(
            (acc: Array<QuoteWizardAnswerInstance>, subAnswer) => {
              const mapped = mapAnswerToReduxState(
                subAnswer,
                matchedQuestionInstance.subQuestions,
                language,
              );
              if (mapped) {
                acc = [...acc, mapped];
              }
              return acc;
            },
            [],
          )
        : [],
  };
  return new QuoteWizardAnswerInstance(input);
}

/**
 * Component-specific error handling
 * @param error - the error to process
 * @return - Either return a valid react element or nothing (to have control pass back to the handler function)
 */
export const baseHandleSpecificErrors = (
  error: { message: string },
  uuid: string,
) => {
  if (error.message.match(/is not a valid uuid/i)) {
    return <InvalidUUIDError uuid={uuid} />;
  }
  if (error.message.match(/quote matching query does not exist/i)) {
    return <MissingQuoteError uuid={uuid} />;
  }
};

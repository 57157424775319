import { graphql } from '../../gql';

export const CREATE_QUOTE_FROM_PDF = graphql(`
  mutation createQuoteFromPdf(
    $businessLineId: ID!
    $policyIds: [ID!]!
    $quotePdf: String!
    $extension: String!
  ) {
    createQuoteFromPdf(
      businessLineId: $businessLineId
      policyIds: $policyIds
      quotePdf: $quotePdf
      extension: $extension
    ) {
      taskPoolId
    }
  }
`);

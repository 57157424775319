import { RouteComponentProps } from '@reach/router';
import { useUsingVagoSettings } from '../../../../../hooks';
import { VagoQuoteList } from './Vago';
import { BasicQuoteList } from './BasicQuoteList';
import Page from '../../../components/Page';
import Container from '@mui/material/Container';

interface QuoteListProps extends RouteComponentProps { }
export const QuoteList = (_props: QuoteListProps) => {
  const onVago = useUsingVagoSettings();

  return (
    <Page title='Applications | Manager'>
      <Container maxWidth={false} data-testid='quotes'>
        {onVago ? <VagoQuoteList /> : <BasicQuoteList />}
      </Container>
    </Page>
  );
};

import { graphql } from '../../gql';

export const EDIT_INSURANCE_POLICY = graphql(`
  mutation editInsurancePolicy(
    $token: String!
    $insurancePolicyId: ID!
    $policyNumber: String
  ) {
    editInsurancePolicy(
      token: $token
      insurancePolicyId: $insurancePolicyId
      policyNumber: $policyNumber
    ) {
      policy {
        id
        policyNumber
      }
    }
  }
`);

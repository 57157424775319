import {
  Dispatch,
  SetStateAction,
  useCallback,
  // useCallback,
  useEffect,
  // useRef,
  // useEffect,
  useState,
} from 'react';
import { Quote } from '../../../../../Typescript/backend/classes';
import {
  Coverage,
  InsurancePolicy,
  Peril,
} from '../../../../../Typescript/classes';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { styled } from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import {
  displayPerilPremium,
  displayPerilLimits,
  displayPerilRate,
} from './utility';
import Checkbox from '@mui/material/Checkbox';
import { useMutation } from '@apollo/client';
import { EDIT_PERILS } from '../../../../../queries';
import { useAuth } from '@calefy-inc/authentication';
import { useSnackbar } from 'notistack';
import {
  EditPerilInput,
  VagoCommercialPackBindingOptions,
} from '../../../../../gql/graphql';
import Bugsnag from '@bugsnag/browser';
import {
  StyledTable,
  StyledTr,
  StyledTd,
  StyledTh,
  StyledTbody,
  StyledThead,
} from './PremiumOverridesTable';
import { PremiumOverrideConfirmationDialog } from './PremiumOverrideConfirmationDialog';
import {
  useDownloadVagoDocumentation,
  useUsingVagoSettings,
} from '../../../../../hooks';
// import { NumberInput } from '@calefy-inc/informedMaterial';
import MaterialTextField from '@mui/material/TextField';
import { VagoDisplayAdditionalCoverages } from './Vago';
import {
  VagoAdditionalCoveragePerilNames,
  vagoAdditionalCoveragePerilNames,
} from './Vago/constants';
import { stringToNumber } from '../../../../../util';
import { AlreadyBoundTooltip } from './AlreadyBoundTooltip';
// @ts-expect-error
import { PerilRateDisplay } from './PerilRateDisplay';

interface InsurancePolicyDisplayProps {
  quote: Quote;
  insurancePolicy: InsurancePolicy;
}
export const InsurancePolicyDisplay = ({
  quote,
  insurancePolicy,
}: InsurancePolicyDisplayProps) => {
  const [perilsToEdit, setPerilsToEdit] = useState<Array<EditPerilInput>>([]);
  const [confirmationDialogOpen, setConfirmationDialogOpen] =
    useState<boolean>(false);
  const { token } = useAuth();
  const onVago = useUsingVagoSettings();
  const [additionalCoveragePerils, setAdditionalCoveragePerils] = useState<
    Array<Peril>
  >([]);
  const { enqueueSnackbar } = useSnackbar();
  const [downloadVagoDocumentation, _rest] = useDownloadVagoDocumentation({
    uuid: quote.uniqueId,
    onSuccess: () =>
      enqueueSnackbar('Downloaded documents', { variant: 'success' }),
    onFailure: (error) => {
      enqueueSnackbar(`Error downloading documentation: ${error.message}`, {
        variant: 'error',
      });
    },
  });
  const [
    editPerils,
    {
      loading,
      error,
      // data
    },
  ] = useMutation(EDIT_PERILS, {
    refetchQueries: ['quoteInsurancePolicies'],
    onCompleted: (data) => {
      if (data?.editPerils) {
        enqueueSnackbar('Successfully updated', { variant: 'success' });
        setPerilsToEdit([]);
        if (downloadVagoDocumentation) {
          setTimeout(() => downloadVagoDocumentation(), 3000);
        }
      }
    },
  });

  useEffect(() => {
    if (error) {
      enqueueSnackbar('Error updating', { variant: 'error' });
      Bugsnag.notify(error);
    }
  }, [error]);

  // Separate out the vago additional coverage perils
  useEffect(() => {
    if (!onVago) {
      return;
    }
    setAdditionalCoveragePerils(
      insurancePolicy.coverages.reduce((acc: Array<Peril>, coverage) => {
        return [
          ...acc,
          ...coverage.perils
            .filter((peril) =>
              vagoAdditionalCoveragePerilNames.find(
                (additionalCoveragePerilName) =>
                  additionalCoveragePerilName === peril.name,
              ),
            )
            .map((peril) =>
              peril.name ===
              VagoAdditionalCoveragePerilNames.EQUIPMENT_BREAKDOWN
                ? peril.copyWithAmendments({
                    displayName: `Boiler - ${coverage.getAllCoveredLocationsString()}`,
                  })
                : peril,
            ),
        ];
      }, []),
    );
  }, [onVago, insurancePolicy]);

  /**
   * One more change and this becomes a separate component
   */
  const SaveButton = () => {
    if (
      insurancePolicy.coverages.length === 0 ||
      insurancePolicy.coverages.every(
        (coverage) => coverage.perils.length === 0,
      )
    ) {
      return null;
    }
    return (
      <LoadingButtonContainer>
        <LoadingButton
          variant='contained'
          loading={loading}
          disabled={!token || perilsToEdit.length === 0}
          onClick={() => {
            setConfirmationDialogOpen(true);
          }}
        >
          Save Changes
        </LoadingButton>
      </LoadingButtonContainer>
    );
  };
  return (
    <InsurancePolicyDisplayContainer>
      <Typography component='h2' variant='h4'>
        {insurancePolicy.description}
      </Typography>
      <SaveButton />
      {error ? (
        <ErrorSpan>Error updating premiums: {error.message}</ErrorSpan>
      ) : null}
      {insurancePolicy.coverages.map((coverage) => {
        return (
          <DisplayCoverage
            quote={quote}
            insurancePolicy={insurancePolicy}
            coverage={coverage}
            setPerilsToEdit={setPerilsToEdit}
            perilsFilter={(p) =>
              vagoAdditionalCoveragePerilNames.find(
                (name) => p.name === name,
              ) === undefined
            }
            key={coverage.id}
          />
        );
      })}
      <VagoDisplayAdditionalCoverages
        bindings={quote.bindings}
        perils={additionalCoveragePerils}
        setPerilsToEdit={setPerilsToEdit}
      />
      <PremiumOverrideConfirmationDialog
        open={confirmationDialogOpen}
        onConfirm={() => {
          setConfirmationDialogOpen(false);
          editPerils({
            variables: {
              token,
              perils: perilsToEdit,
            },
          });
        }}
        onCancel={() => {
          setConfirmationDialogOpen(false);
        }}
      />
      <SaveButton />
    </InsurancePolicyDisplayContainer>
  );
};

const LoadingButtonContainer = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'end',
});

const InsurancePolicyDisplayContainer = styled('section')({
  width: '100%',
});

interface DisplayCoverageProps {
  quote: Quote;
  insurancePolicy: InsurancePolicy;
  coverage: Coverage;
  setPerilsToEdit: Dispatch<SetStateAction<Array<EditPerilInput>>>;
  perilsFilter?: (p: Peril) => boolean; // an optional filter to apply to the filters to display
  displayName?: string; // an optional override to the display name
}
const DisplayCoverage = ({
  quote,
  // insurancePolicy,
  setPerilsToEdit,
  coverage,
  perilsFilter,
  displayName,
}: DisplayCoverageProps) => {
  const [bound, setBound] = useState<boolean>(false);
  const [perils, setPerils] = useState<Array<Peril>>([]);
  const [perilOverrides, setPerilOverrides] = useState<Array<Peril>>([]); // these are perils that are being modified from some other peril - e.g. when building changes, contents will appear here
  const onVago = useUsingVagoSettings();

  // when we get the coverage, change the perils
  useEffect(() => {
    setPerils([...coverage.perils]);
  }, [coverage]);

  // change the bound status based on the coverage and the bindings
  useEffect(() => {
    if (quote && coverage) {
      setBound(
        onVago &&
          ((coverage.name === 'Property' &&
            quote.bindings.some(
              (binding) =>
                binding.matchesLabel(
                  VagoCommercialPackBindingOptions.Property,
                ) && binding.bound,
            )) ||
            (coverage.name === 'Liability' &&
              quote.bindings.some(
                (binding) =>
                  binding.matchesLabel(
                    VagoCommercialPackBindingOptions.Liability,
                  ) && binding.bound,
              ))),
      );
    }
  }, [quote, coverage, onVago]);

  if (coverage.perils.length === 0) {
    return null;
  }
  return (
    <DisplayCoverageContainer>
      <Typography component='h3' variant='h5' sx={{ alignSelf: 'start' }}>
        {displayName ? displayName : coverage.getDisplayName()}
      </Typography>
      <StyledTable>
        <StyledThead>
          <StyledTr>
            {['Peril', 'Limits', 'Rate', 'Premium', 'Override?'].map(
              (header) => (
                <StyledTh scope='col' key={header}>
                  {header}
                </StyledTh>
              ),
            )}
          </StyledTr>
        </StyledThead>
        <StyledTbody>
          {perils
            .filter(perilsFilter ? perilsFilter : () => true)
            .map((peril, _, allFilteredPerils) => {
              return (
                <PerilRow
                  key={peril.id}
                  peril={peril}
                  perils={allFilteredPerils}
                  perilOverrides={perilOverrides}
                  setPerilOverrides={setPerilOverrides}
                  setPerils={setPerils}
                  updatePerils={setPerilsToEdit}
                  bound={bound}
                />
              );
            })}
        </StyledTbody>
      </StyledTable>
    </DisplayCoverageContainer>
  );
};

export const DisplayCoverageContainer = styled('section')(({ theme }) => {
  return {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
    marginBottom: theme.spacing(3),
    '&:last-of-type': {
      marginBottom: theme.spacing(1),
    },
  };
});

interface PerilRowProps {
  peril: Peril;
  perils?: Array<Peril>;
  setPerils?: Dispatch<SetStateAction<Array<Peril>>>;
  updatePerils: Dispatch<SetStateAction<Array<EditPerilInput>>>;
  displayName?: string; // optionally, a name to use instead of the one from the backend
  bound?: boolean;
  perilOverrides?: Array<Peril>;
  setPerilOverrides?: Dispatch<SetStateAction<Array<Peril>>>;
  // basis?: number; // optionally, the basis to use for the premium (premium = rate * basis); by default it's the limit
}
export const PerilRow = ({
  peril: originalPeril,
  perils = [],
  setPerils,
  updatePerils,
  displayName,
  bound,
  perilOverrides,
  setPerilOverrides,
}: // basis,
PerilRowProps) => {
  const [displayValue, setDisplayValue] = useState<string>('');
  const [displayRate, setDisplayRate] = useState<string>('');
  const [checked, setChecked] = useState<boolean>(originalPeril.override);
  const [peril, setPeril] = useState<Peril>(originalPeril);
  const [isMatch, setIsMatch] = useState<boolean>(true);
  const { token } = useAuth();

  // whenever we update the peril, add it to the list to update
  useEffect(() => {
    if (peril.match(originalPeril)) {
      updatePerils((oldPerils) =>
        oldPerils.filter((oldperil) => oldperil.perilId !== peril.id),
      );
    } else {
      updatePerils((oldPerils) => [
        ...oldPerils.filter((oldperil) => oldperil.perilId !== peril.id),
        {
          perilId: peril.id,
          premium: String(peril.premium),
          rate: peril.rate === undefined ? undefined : String(peril.rate),
          override: peril.override,
        },
      ]);
      const rateAsDecimal = peril.rate;
      // if it's the building peril we need to update contents, lor, and any misc coverages
      if (peril.name === 'Building' && Array.isArray(perils) && setPerils) {
        const relevantPerils = perils.filter((peril) => {
          return (
            ['Contents', 'Loss of Rent'].includes(peril.name) ||
            /miscellaneous - .+/i.exec(peril.name)
          );
        });
        relevantPerils.forEach((otherPeril) => {
          const otherPerilPremium = otherPeril.limit * rateAsDecimal;
          setPerilOverrides &&
            setPerilOverrides((oldPerilOverrides) => {
              const newPerilOverride = otherPeril.copyWithAmendments({
                override: true,
                rate: rateAsDecimal,
                premium: otherPerilPremium,
              });
              return [
                ...oldPerilOverrides.filter(
                  (oldPerilOverride) => oldPerilOverride.id !== otherPeril.id,
                ),
                newPerilOverride,
              ];
            });
        });
      }
    }
  }, [peril]);

  // whenever the peril changes, change the display value and rate
  useEffect(() => {
    setDisplayValue(displayPerilPremium(originalPeril.premium));
    setDisplayRate(displayPerilRate(originalPeril.rate * 100));
    setPeril(originalPeril);
    setChecked(originalPeril.override);
  }, [originalPeril]);

  // check that the peril and original peril are the same
  useEffect(() => {
    setIsMatch(peril.match(originalPeril));
  }, [peril, originalPeril]);

  // see if there's an override
  useEffect(() => {
    if (Array.isArray(perilOverrides)) {
      const matched = perilOverrides.find(
        (perilOverride) => perilOverride.id === originalPeril.id,
      );
      if (matched) {
        setPeril(matched);
        setDisplayValue(displayPerilPremium(matched.premium));
        setDisplayRate(displayPerilRate(matched.rate * 100));
        setChecked(matched.override);
      }
    }
  }, [originalPeril, perilOverrides]);

  // when we uncheck it, everything should revert to the way it was
  useEffect(() => {
    if (!checked) {
      setDisplayValue(displayPerilPremium(peril.premium));
      setDisplayRate(displayPerilRate(peril.rate * 100));
    }
  }, [checked]);

  const clearPerilOverrides = useCallback(
    (perilOverrideId: Peril['id']) => {
      setPerilOverrides &&
        setPerilOverrides((oldPerilOverrides) => {
          return oldPerilOverrides.filter(
            (oldPerilOverride) => oldPerilOverride.id !== perilOverrideId,
          );
        });
    },
    [setPerilOverrides],
  );

  return (
    <StyledTr
      key={peril.id}
      sx={
        !isMatch
          ? {
              backgroundColor: 'rgba(0, 0, 0, 0.05)',
            }
          : {}
      }
    >
      <StyledTd>
        <AlreadyBoundTooltip bound={!!bound}>
          {displayName || peril.displayName || peril.name}
        </AlreadyBoundTooltip>
      </StyledTd>
      <StyledTd>
        <AlreadyBoundTooltip bound={!!bound}>
          {displayPerilLimits(peril.limit)}
        </AlreadyBoundTooltip>
      </StyledTd>
      <StyledTd>
        <AlreadyBoundTooltip bound={!!bound}>
          {peril.override ? (
            <MaterialTextField
              fullWidth
              disabled={bound}
              variant='standard'
              value={displayRate}
              InputProps={{
                endAdornment: <InputAdornment position='end'>%</InputAdornment>,
              }}
              onChange={(e) => {
                setDisplayRate(e.target.value);
              }}
              onBlur={() => {
                const asPercent = stringToNumber(displayRate);
                if (asPercent === null) {
                  setDisplayRate(displayPerilRate(peril.rate));
                } else {
                  const asDecimal = asPercent / 100;
                  const newPremium = peril.getBasis() * asDecimal;
                  setDisplayRate(displayPerilRate(asPercent));
                  setDisplayValue(displayPerilPremium(newPremium));
                  setPeril(
                    peril.copyWithAmendments({
                      rate: asDecimal,
                      premium: newPremium,
                    }),
                  );
                  clearPerilOverrides(peril.id);
                }
              }}
            />
          ) : (
            displayPerilRate(peril.rate, { displayUnit: true })
          )}
        </AlreadyBoundTooltip>
      </StyledTd>
      <StyledTd>
        <AlreadyBoundTooltip bound={!!bound}>
          {peril.override ? (
            <MaterialTextField
              fullWidth
              disabled={bound}
              variant='standard'
              value={displayValue}
              InputProps={{
                startAdornment: (
                  <InputAdornment position='start'>$</InputAdornment>
                ),
              }}
              onChange={(e) => {
                setDisplayValue(e.target.value);
              }}
              onBlur={() => {
                const converted = stringToNumber(displayValue);
                if (converted === null) {
                  setDisplayValue(displayPerilPremium(peril.premium));
                } else {
                  const newRateAsDecimal = converted / peril.getBasis();
                  const newRateAsPercent = newRateAsDecimal * 100;
                  setDisplayValue(displayPerilPremium(converted));
                  setDisplayRate(displayPerilRate(newRateAsPercent));
                  setPeril(
                    peril.copyWithAmendments({
                      premium: converted,
                      rate: newRateAsDecimal,
                    }),
                  );
                  clearPerilOverrides(peril.id);
                }
              }}
            />
          ) : (
            displayPerilPremium(peril.premium, { displayUnit: true })
          )}
        </AlreadyBoundTooltip>
      </StyledTd>
      <StyledTd>
        <AlreadyBoundTooltip bound={!!bound}>
          <Checkbox
            disabled={bound || !token || originalPeril.override}
            checked={checked}
            onChange={(e) => {
              const checked = e.target.checked;
              setChecked(checked);
              setPeril((oldPeril) => {
                if (checked) {
                  return oldPeril.copyWithAmendments({
                    override: checked,
                  });
                } else {
                  return originalPeril.copy();
                }
              });
              clearPerilOverrides(peril.id);
            }}
          />
        </AlreadyBoundTooltip>
      </StyledTd>
    </StyledTr>
  );
};

const ErrorSpan = styled('span')(({ theme }) => {
  return {
    color: theme.palette.error.main,
  };
});

import { graphql } from '../../gql';

export const EDIT_INSURANCE_POLICY_DATES = graphql(`
  mutation editInsurancePolicyDates(
    $token: String!
    $insurancePolicyId: ID!
    $policyEffectiveDate: String
    $policyExpiryDate: String
  ) {
    editInsurancePolicyDates(
      token: $token
      insurancePolicyId: $insurancePolicyId
      policyEffectiveDate: $policyEffectiveDate
      policyExpiryDate: $policyExpiryDate
    ) {
      insurancePolicy {
        id
        policyEffectiveDate
        policyExpiryDate
      }
    }
  }
`);

import { useEffect, useState } from 'react';
import { Quote } from '../Typescript/backend/classes';
import { useLazyQuery } from '@apollo/client';
import { QUOTE_INSURANCE_POLICIES } from '../queries';
import { InsurancePolicy } from '../Typescript/classes';
import { useAuth } from '@calefy-inc/authentication';

export const useQuoteInsurancePolicies = (quote: Quote | Quote['uniqueId']) => {
  const [getQuoteInsurancePolicies, result] = useLazyQuery(
    QUOTE_INSURANCE_POLICIES,
  );
  const [insurancePolicies, setInsurancePolicies] = useState<
    Array<InsurancePolicy>
  >([]);
  const { token } = useAuth();

  useEffect(() => {
    if (token && quote) {
      const uniqueId = typeof quote === 'string' ? quote : quote.uniqueId;
      getQuoteInsurancePolicies({
        variables: {
          token,
          uniqueId,
        },
      });
    }
  }, [token, quote]);

  useEffect(() => {
    if (
      result.data?.quoteInsurancePolicies &&
      result.data.quoteInsurancePolicies.length > 0
    ) {
      const insurancePolicies = result.data.quoteInsurancePolicies.map(
        (backendInsurancePolicy) => {
          const policy = InsurancePolicy.createFromBackendResponse(
            backendInsurancePolicy,
          );
          policy.coverages.forEach((coverage) => {
            coverage.perils.forEach((peril) => {
              peril.premium = Math.round(peril.premium);
            });
          });
          return policy;
        },
      );
      setInsurancePolicies(insurancePolicies);
    }
  }, [result.data]);

  return [insurancePolicies, result] as [
    typeof insurancePolicies,
    typeof result,
  ];
};

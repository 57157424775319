import { useEffect, useState } from 'react';
import { useLazyQuery } from '@apollo/client';
import { VAGO_IBC_LIST } from '../queries';
import { VagoIbcListQuery } from '../gql/graphql';
import { ArrayElement } from '@calefy-inc/utilityTypes';
import { useAuth } from '@calefy-inc/authentication';
import { isNotNullish } from '../util';

type VagoIbcType = ArrayElement<NonNullable<VagoIbcListQuery['vagoIbcList']>>;
export const useVagoIbcList = () => {
  const [businesses, setBusinesses] = useState<
    Array<
      Pick<VagoIbcType, 'id' | 'displayName'> & {
        ibcCode: VagoIbcType['sicCode'];
      }
    >
  >([]);

  const [getIbcList, { data }] = useLazyQuery(VAGO_IBC_LIST);
  const { token } = useAuth();

  useEffect(() => {
    if (token) {
      getIbcList({ variables: { token } });
    }
  }, [token]);

  // update the list business when we get the data
  useEffect(() => {
    if (data?.vagoIbcList) {
      setBusinesses(
        data.vagoIbcList.filter(isNotNullish).map((ibcListElement) => ({
          id: ibcListElement.id,
          displayName: ibcListElement.displayName,
          ibcCode: ibcListElement.sicCode,
        })),
      );
    }
  }, [data]);

  return businesses
    .filter((business) => business.ibcCode !== 0)
    .sort((a, b) => a.ibcCode - b.ibcCode)
    .map((business) => {
      const label = `${business.ibcCode} - ${business.displayName}`;
      return label;
    });
};
